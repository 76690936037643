<!-- 学科优势测评报告 -->
<template>
  <div class="main">
    <div class="main-title">
      <span
        style="left: 0; padding-left: 2rem; position: absolute; color: #2EA9DF;"
        >VIP版</span
      ><span>学科优势匹配结果报告</span>
      <div class="print-btn" @click="print()">
        <div class="print-btn-text-wrapper">
          <span>打印</span><span>报告</span>
        </div>
      </div>
    </div>
    <div class="main-parts-wrapper">
      <div class="main-part">
        <div class="part-title">学科优势匹配结果</div>
        <div class="part-describe">
          学科优势测评报告是在测评数据的支持下，从学生兴趣出发，针对学生测评结果对学生学科优势做出的分析。通过此项测试，可帮助了解各科情况同时根据各项能力值给到匹配的选科与专业推荐
        </div>
        <!-- 条形图 -->
        <div class="part-content" id="container" :style="chartheight"></div>
      </div>
    </div>
    <div class="main-buttons-wrapper">
      <!-- <a
        class="lj-btn lj-btn-third lj-btn-block main-button"
        @click="jumpPage('HomeMajorStdResultPage3')"
        >上一页</a
      > -->
      <a
        class="lj-btn lj-btn-default lj-btn-block main-button"
        @click="jumpPage('reportCenter')"
        >返回目录</a
      >
      <!-- <a
        class="lj-btn lj-btn-primary lj-btn-block main-button"
        @click="jumpPage('HomeMajorStdResultPage5')"
        >下一页</a
      > -->
    </div>
  </div>
</template>

<script>
import './../../../../Public/utils/chartjs/Chart'
import './../../../../Public/utils/highcharts/highcharts'
import { querySubject } from './../../../../api/major'
import Highcharts from './../../../../Public/utils/highcharts/highcharts'
export default {
  data: function () {
    return {
      score: [76, 23],
      subject: ['计算机', '金融'],
      chartheight: {
        'min-height': '480px'
      },
      chart: {}
    }
  },
  computed: {
    chartOptions: function () {
      return {
        chart: { type: 'bar' },
        xAxis: {
          categories: this.subject,
          title: {
            text: '专业类',
            style: { fontSize: '16px' }
          },
          labels: {
            step: 1,
            staggerLines: 1,
            style: { fontSize: '14px' }
          }
        },
        title: { text: null },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: '匹配度',
            align: 'high',
            style: { fontSize: '16px' }
          },
          labels: { format: '{value}' }
        },
        legend: { enabled: !1 },
        tooltip: { valueDecimals: 2, valueSuffix: '' },
        plotOptions: {
          bar: {
            dataLabels: { enabled: !0, format: '{y}' }
          },
          series: { pointWidth: 30 }
        },
        credits: { enabled: !1 },
        series: [{ name: '匹配度', data: this.score }]
      }
    },
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  created: function () {
    if (this.isMobile !== null) {
      this.$router.push({
        path: 'HomeMajorStdResultPage4Mobile'
      })
      return
    }
    querySubject({}).then(res => {
      if (res.rescode == 200) {
        console.log('querySubject', res)
        this.score = res.data.score
        this.subject = res.data.subject
        this.initChart()
      }
    })
  },
  methods: {
    jumpPage: function (dest) {

      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    initChart () {
      this.chart = new Highcharts.Chart(
        document.getElementById('container'),
        this.chartOptions
      )
    },

    print: function () {
      this.$router.push({
        path: 'HomeMajorStdPrint'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.part-describe {
    padding: 1rem 2rem;
    text-indent: 1.8rem;
}
.main-title {
  padding: 0;
}
</style>
